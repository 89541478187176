import styled from "@emotion/styled";
import { AuthSignupPost201ResponseRoleEnum } from "@sizlcorp/mbk-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { users } from "api/users/useUsersQuery";
import NavbarTop from "components/Layout/NavBarTop";
import { SideBar } from "components/Layout/SideBar";
import ProtectedRoute from "components/Router/ProtectedRoute";
import Auth from "pages/auth";
import Dashboard from "pages/dashboard";
import { DataManagementPage } from "pages/dataManagement";
import InformationPage from "pages/informationManagement";
import PendingReasonPage from "pages/pendingReasons/PendingReasons";
import ProcessTypePage from "pages/processTypes";
import Reception from "pages/receptions";
import ReceptionArchive from "pages/receptionsArchive";
import SiteConfigPage from "pages/siteconfig";
import Users from "pages/user";
import { Outlet, Route, Routes } from "react-router-dom";
import { useCheckAuth } from "utils/checkAuth";

function Index() {
  const auth = useCheckAuth();
  const { data } = useQuery(users.who({}));
  const role = data?.data?.role as AuthSignupPost201ResponseRoleEnum;

  return (
    <Routes>
      <Route element={<Layout auth={auth} />}>
        {/* 로그인되지 않은 경우 Auth 페이지로 이동 */}
        <Route
          path="/"
          element={
            <ProtectedRoute redirectCondition={auth} redirectPath="/dashboard">
              <Auth />
            </ProtectedRoute>
          }
        />

        {/* MBK, DEALER 권한은 제외하고 나머지 권한만 Dashboard 페이지 접근 가능 */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[
                AuthSignupPost201ResponseRoleEnum.TECHNICIAN,
                AuthSignupPost201ResponseRoleEnum.ADVISER,
                AuthSignupPost201ResponseRoleEnum.ADMIN,
              ]}
              role={role}
            >
              <Dashboard />
            </ProtectedRoute>
          }
        />

        {/* Reception 페이지는 TECHNICIAN, ADVISER, ADMIN만 접근 가능 */}
        <Route
          path="/reception"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[
                AuthSignupPost201ResponseRoleEnum.TECHNICIAN,
                AuthSignupPost201ResponseRoleEnum.ADVISER,
                AuthSignupPost201ResponseRoleEnum.ADMIN,
                AuthSignupPost201ResponseRoleEnum.DEALER,
              ]}
              role={role}
            >
              <Reception />
            </ProtectedRoute>
          }
        />

        {/* Reception Archive 페이지는 TECHNICIAN, ADVISER, ADMIN만 접근 가능 */}
        <Route
          path="/receptionArchive"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[
                AuthSignupPost201ResponseRoleEnum.TECHNICIAN,
                AuthSignupPost201ResponseRoleEnum.ADVISER,
                AuthSignupPost201ResponseRoleEnum.ADMIN,
                AuthSignupPost201ResponseRoleEnum.DEALER,
              ]}
              role={role}
            >
              <ReceptionArchive />
            </ProtectedRoute>
          }
        />

        {/* Users 페이지는 ADMIN만 접근 가능 */}
        <Route
          path="/users"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[AuthSignupPost201ResponseRoleEnum.ADMIN]}
              role={role}
            >
              <Users />
            </ProtectedRoute>
          }
        />

        {/* Information Management 페이지는 다양한 권한이 접근 가능 */}
        <Route
          path="/informationManagement"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[
                AuthSignupPost201ResponseRoleEnum.TECHNICIAN,
                AuthSignupPost201ResponseRoleEnum.ADVISER,
                AuthSignupPost201ResponseRoleEnum.ADMIN,
                AuthSignupPost201ResponseRoleEnum.DEALER,
                AuthSignupPost201ResponseRoleEnum.MBK,
              ]}
              role={role}
            >
              <InformationPage />
            </ProtectedRoute>
          }
        />

        {/* Site Config 페이지는 ADMIN, DEALER, MBK만 접근 가능 */}
        <Route
          path="/siteconfig"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[
                AuthSignupPost201ResponseRoleEnum.ADMIN,
                AuthSignupPost201ResponseRoleEnum.DEALER,
                AuthSignupPost201ResponseRoleEnum.MBK,
              ]}
              role={role}
            >
              <SiteConfigPage />
            </ProtectedRoute>
          }
        />

        {/* Pending Reason 페이지는 ADMIN만 접근 가능 */}
        <Route
          path="pendingReason"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[AuthSignupPost201ResponseRoleEnum.ADMIN]}
              role={role}
            >
              <PendingReasonPage />
            </ProtectedRoute>
          }
        />

        {/* Process Type 페이지는 ADMIN만 접근 가능 */}
        <Route
          path="processType"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[AuthSignupPost201ResponseRoleEnum.ADMIN]}
              role={role}
            >
              <ProcessTypePage />
            </ProtectedRoute>
          }
        />

        {/* Data Management 페이지는 ADMIN, DEALER, MBK만 접근 가능 */}
        <Route
          path="dataManagement"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[
                AuthSignupPost201ResponseRoleEnum.ADMIN,
                AuthSignupPost201ResponseRoleEnum.DEALER,
                AuthSignupPost201ResponseRoleEnum.MBK,
              ]}
              role={role}
            >
              <DataManagementPage />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
}

const Layout = ({ auth }: { auth: boolean }) => {
  return (
    <div style={{ height: "100vh", background: "#000" }}>
      {!auth ? (
        <Outlet />
      ) : (
        <>
          <Wrapper>
            <SideBar />
            <Container>
              <NavbarTop />
              <Outlet />
            </Container>
          </Wrapper>
        </>
      )}
    </div>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Container = styled.div`
  width: calc(100% - 210px);
  height: 100%;
  margin-left: 210px;
  background: #f5f6fb;

  @media (max-width: 1199px) {
    & {
      width: 100%;
      margin-left: 0;
    }
  }
`;

export default Index;
