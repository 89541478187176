import { Button, Flex, Pagination, Select, Text } from "@mantine/core";
import BaseBox from "components/Box/BaseBox";
import BaseContents from "components/Contents/BaseContents";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";

import {
  TicketApiTicketsRemoveDeleteRequest,
  TicketsGet200ResponseRowsInner,
  TicketsGet200ResponseRowsInnerStepStatusEnum,
} from "@sizlcorp/mbk-api-document/dist/models";
import { IconCalendar, IconTrash } from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import usePrintPost from "api/print/usePrintPost";
import { mutateTickets, tickets } from "api/tickets/useTicketsQuery";
import { Confirm } from "components/Confirm";
import { Loading } from "components/Loading";
import SearchBox, { SearchProps } from "components/SearchBox";
import { pageOptions } from "constants/pageOptions";
import {
  convertFilterToKorean,
  receptionStatusKeys,
  receptionStatusList,
} from "constants/receptionStatusList";
import { receptionSearchType } from "constants/searchType";
import dayjs from "dayjs";
import { icons } from "modules/icons";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getStartAndEndOfDateRange } from "utils/rangeDate";
import { isCalendarDate } from "./Calendar";
import { FileImportForm } from "./ExcelModal";
import ReceptionTable, { SortProps } from "./ReceptionTable";
import RegisterForm from "./RegisterForm";
import { useReceptionActions } from "./hook/ReceptionAction";
import { useReceptionState } from "./hook/ReceptionState";
import { CalendarDatePicker } from "components/Date/CalendarDatePicker";
import { users } from "api/users/useUsersQuery";

const Reception = Object.assign({}, "", {
  Content: BaseContents,
  Box: BaseBox,
  SearchBox: SearchBox,
});

const filteredSearchType = receptionSearchType
  .filter((type) => type.value)
  .map((type) => type.value);

function ReceptionPage() {
  const { data: userData } = useQuery(users.who({}));

  const location = useLocation();
  const [showOnlyMine, setShowOnlyMine] = useState<boolean>(
    location?.state?.showOnlyMine ?? false
  );
  const { openModal, closeModal } = useModal();
  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<any> => new Set()
  );

  const [filter, setFilter] = useState<keyof typeof receptionStatusList>(
    location?.state?.status || "ALL"
  );

  const { mutate: mutatePrint } = usePrintPost();
  const queryClient = useQueryClient();

  const { mutate: removeTickets } = useMutation(
    (params: TicketApiTicketsRemoveDeleteRequest) =>
      mutateTickets
        .removeDelete(params)
        .mutationFn(params as TicketApiTicketsRemoveDeleteRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  const [perPageRows, setPerPageRows] = useState(5); // code changes for ticket #564 by TBI
  const [sortColumns, setSortColumns] = useState<Map<string, SortProps>>(
    (new Map() as Map<string, SortProps>).set("incomingAt", {
      columnKey: "incomingAt",
      direction: undefined,
    })
  );

  const [search, setSearch] = useState<SearchProps>({
    search: "",
    searchFields: [],
    pageSize: "10",
  });

  const state: any = useReceptionState();
  const action: any = useReceptionActions();

  const [page, setPage] = useState<number>(1);

  const sortedColumns = Array.from(sortColumns.values()).filter(
    (value) => value.direction
  );

  const stepStatusFilterQuery =
    filter === "ALL"
      ? [
          {
            stepStatus: {
              $ne: TicketsGet200ResponseRowsInnerStepStatusEnum.DELIVERED,
            },
          },
          {
            stepStatus: {
              $ne: TicketsGet200ResponseRowsInnerStepStatusEnum.CLOSED,
            },
          },
        ]
      : [{ stepStatus: receptionStatusList[filter] as string[] }];

  const userQuery = showOnlyMine
    ? { assignedAdviserId: { $eq: localStorage.getItem("userId") } }
    : {};

  const ticketQuery = location?.state?.ticketId
    ? { id: { $eq: location?.state?.ticketId } }
    : {};

  const {
    data,
    refetch,
    isLoading: isTicketsLoading,
  } = useQuery(
    tickets.getView({
      query: [
        JSON.stringify({
          dealerHqCode:
            Number(localStorage.getItem("siteId")) === 99
              ? undefined
              : userData?.data?.site?.dealerHqCode,
          siteId:
            Number(localStorage.getItem("siteId")) === 99 ||
            userData?.data.role === "DEALER"
              ? undefined
              : Number(localStorage.getItem("siteId")),
          $and: [...stepStatusFilterQuery, userQuery, ticketQuery],
          incomingAt: {
            $or: [
              {
                $between: location.state?.date
                  ? getStartAndEndOfDateRange(
                      location.state?.date,
                      location.state?.date
                    )
                  : getStartAndEndOfDateRange(state.Date[0], state.Date[1]),
              },
              { $eq: null },
            ],
          },
        }),
      ],
      page: page,
      pageSize: perPageRows,
      populate: [
        "stage",
        "site",
        "workbay",
        "carModel",
        "status",
        "createUser",
        "pendingReason",
        "stagesInfo",
        "assignedAdviser",
        "assignedTechnician",
      ],
      search: search.search,
      searchFields: search.searchFields.length
        ? search.searchFields
        : filteredSearchType,
      sort:
        sortedColumns.length < 1
          ? "-id"
          : sortedColumns
              .map((value) =>
                value.direction === "DESC"
                  ? `-${value.columnKey}`
                  : `${value.columnKey}`
              )
              .join(","),
    })
  );

  useEffect(() => {
    if (state.Date[0]) refetch();
  }, [state.Date]);

  const { data: ticketIdData } = useQuery(
    tickets.detail({ ticketId: selectedRows.values().next().value })
  );

  const printQRLabel = () => {
    const values = selectedRows.values();
    const ticketIds = Array.from(values);
    if (selectedRows && selectedRows.size > 0) {
      mutatePrint({
        ticketId: ticketIds,
      });
    }
  };

  const moveToLandingPage = () => {
    const ticketsId = Array.from(selectedRows.values());
    if (selectedRows.size == 1) {
      window.open(
        `https://mbk-staging-customer.sizl.co.kr/?ticketId=${ticketsId}`
      );
    }
  };

  const TicketIdsRemove = () => {
    const ticketIds = Array.from(selectedRows.values());
    if (selectedRows && selectedRows.size > 0) {
      removeTickets(
        {
          ticketsRemoveDeleteRequest: {
            ticketIds: ticketIds,
          },
        },
        {
          onSuccess: (res) => {
            alert("삭제되었습니다.");
            setSelectedRows(new Set()); // 삭제하고 갖고있던 체크값 초기화
          },
          onError: (err) => {
            console.log(err);
            alert("삭제에 실패하였습니다.");
          },
        }
      );
    }
  };

  const isButtonDisabled = () => {
    return !(
      selectedRows.size === 1 &&
      !(ticketIdData?.data?.stepStatus === "TEMPORARY_RECEIPTED")
    );
  };

  return (
    <Reception.Content
      title={
        <Flex justify={"space-between"}>
          {"접수현황"}
          <Flex gap={5}>
            <Button
              w={"6rem"}
              bg={"yellow"}
              styles={{
                root: {
                  ":hover": {
                    backgroundColor: "orange",
                  },
                },
              }}
              onClick={() => {
                openModal(<FileImportForm />, "", "엑셀 접수");
              }}
              disabled={
                selectedRows.size > 0 &&
                !(
                  selectedRows.size < 2 &&
                  ticketIdData?.data?.stepStatus === "TEMPORARY_RECEIPTED"
                )
              }
            >
              {"엑셀 접수"}
            </Button>
            <Button
              w={"6rem"}
              onClick={() => {
                openModal(
                  <RegisterForm formatterProps={ticketIdData?.data} />,
                  null,
                  "신규 접수",
                  true
                );
              }}
              disabled={
                selectedRows.size > 0 &&
                !(
                  selectedRows.size < 2 &&
                  ticketIdData?.data?.stepStatus === "TEMPORARY_RECEIPTED"
                )
              }
            >
              {"신규 접수"}
            </Button>
          </Flex>
        </Flex>
      }
    >
      <Flex justify="center" align={"center"} my="md">
        <Button
          rightIcon={<IconCalendar size="2rem" color="black" />}
          variant="subtle"
          onClick={() => {
            openModal(
              <CalendarDatePicker
                date={{
                  selectedDateRange: state.Date,
                  setSelectedDateRange: action.setDate,
                }}
              />,
              null,
              "날짜 선택",
              true
            ).then((value) => {
              if (isCalendarDate(value)) action.setDate(value);
            });
          }}
        >
          <Text fw="bold" size="2rem" color="black">
            {location.state?.date
              ? `${dayjs(location.state?.date).format("YYYY.MM.DD")} ~ ${dayjs(
                  location.state?.date
                ).format("YYYY.MM.DD")}`
              : `${dayjs(state.Date[0]).format("YYYY.MM.DD")} ~ ${dayjs(
                  state.Date[1]
                ).format("YYYY.MM.DD")}`}
          </Text>
        </Button>
      </Flex>
      <Flex justify="space-between" gap={5} mb={10}>
        <Flex gap={20} align={"center"}>
          <Select
            placeholder="페이지당 개수"
            data={pageOptions}
            onChange={(e) => {
              setPage(1);
              setPerPageRows(Number(e));
            }}
          ></Select>
          <Flex gap={10}>
            <Button
              variant={showOnlyMine ? "outline" : "filled"}
              onClick={() => {
                setSelectedRows(new Set());
                setShowOnlyMine(false);
                setPage(1);
              }}
            >
              {"전체"}
            </Button>
            <Button
              variant={showOnlyMine ? "filled" : "outline"}
              onClick={() => {
                setSelectedRows(new Set());
                setShowOnlyMine(true);
                setPage(1);
              }}
            >
              {"MY"}
            </Button>
          </Flex>
        </Flex>
        <Flex>
          <SearchBox
            searchType={receptionSearchType}
            setSearch={(searchedValue: any) => {
              setSearch(searchedValue);
              setSelectedRows(new Set());
              setPage(1);
            }}
          />
        </Flex>
      </Flex>

      <Reception.Box>
        {isTicketsLoading && <Loading />}
        <Flex justify={"space-between"} mb={20}>
          <Flex gap={5}>
            <Button
              color="teal"
              disabled={isButtonDisabled()}
              onClick={printQRLabel}
            >
              <icons.PrintIcon />
              <Text ml={5}>{"QR 라벨 출력"}</Text>
            </Button>
            <Button
              color="indigo"
              disabled={isButtonDisabled()}
              onClick={moveToLandingPage}
            >
              <icons.RightArrow />
              <Text ml={5}>{"랜딩 페이지로 이동"}</Text>
            </Button>
            <Button
              color="red"
              disabled={selectedRows.size < 1}
              onClick={() => {
                openModal(
                  <Confirm
                    message="선택한 접수건들을 삭제하시겠습니까?"
                    yesCallback={TicketIdsRemove}
                    noCallback={() => {}}
                    commonCallback={() => closeModal({})}
                  />,
                  null,
                  "삭제 확인"
                );
              }}
            >
              {/* <Button onClick={() => {
            openModal(<Confirm message="삭제하시겠습니까?" yesCallback={() => alert('예')} noCallback={() => alert('아니오')} />, null, '확인?')
          }}>

          </Button> */}
              <IconTrash />
              <Text ml={5}>{"선택 삭제"}</Text>
            </Button>
          </Flex>
          <Flex gap={5}>
            {receptionStatusKeys
              .filter(
                (key) =>
                  key !== "DELIVERED" &&
                  key !== "CLOSED" &&
                  key !== "DELIVERY_PENDING" &&
                  key !== "PENDING" &&
                  key !== "ACCEPT_PENDING"
              )
              .map((name) => {
                return (
                  <Button
                    w={"6rem"}
                    key={name}
                    onClick={() => {
                      setPage(1);
                      setFilter(name);
                      setSelectedRows(new Set());
                    }}
                    bg={filter === name ? "blue" : "rgb(211, 212, 214)"}
                  >
                    {convertFilterToKorean(name)}
                  </Button>
                );
              })}
          </Flex>
        </Flex>
        <ReceptionTable
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          sortColumns={sortColumns}
          setSortColumns={setSortColumns}
          tickets={data?.data?.rows as TicketsGet200ResponseRowsInner[]}
        />
        <Flex justify={"center"} mt={20}>
          <Pagination
            value={page}
            total={data?.data?.totalPages as number}
            onChange={(value) => {
              setPage(value);
              setSelectedRows(new Set());
            }}
          />
        </Flex>
      </Reception.Box>
    </Reception.Content>
  );
}

export default ReceptionPage;
