import { Button, Flex, Pagination, Select } from "@mantine/core";
import BaseBox from "components/Box/BaseBox";
import BaseContents from "components/Contents/BaseContents";

import { AuthSignupPost201Response } from "@sizlcorp/mbk-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { users } from "api/users/useUsersQuery";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";
import SearchBox, { SearchProps } from "components/SearchBox";
import { pageOptions } from "constants/pageOptions";
import { usersSearchType } from "constants/searchType";
import { useState } from "react";
import RegisterForm from "./RegisterForm";
import UsersTable, { SortProps } from "./UsersTable";

const Users = Object.assign({}, "", {
  Content: BaseContents,
  Box: BaseBox,
  SearchBox: SearchBox,
});

const filteredSearchType = usersSearchType
  .filter((type) => type.value)
  .map((type) => type.value);

function UsersPage() {
  const { openModal } = useModal();
  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<any> => new Set()
  );
  const [perPageRows, setPerPageRows] = useState(5); // code changes for ticket #564 by TBI
  const [search, setSearch] = useState<SearchProps>({
    search: "",
    searchFields: [],
    pageSize: "10",
  });
  const [sortColumns, setSortColumns] = useState<Map<string, SortProps>>(
    (new Map() as Map<string, SortProps>).set("createdAt", {
      columnKey: "createdAt",
      direction: undefined,
    })
  );

  const [page, setPage] = useState<number>(1);

  const { data } = useQuery(
    users.get({
      query: [
        JSON.stringify({
          $and: {
            siteId: {
              $eq: localStorage.getItem("siteId"),
            },
            role: {
              $in: ["ADMIN", "ADVISER", "TECHNICIAN"],
            },
          },
        }),
      ],
      page: page,
      pageSize: perPageRows,
      populate: ["site"],
      search: search.search,
      searchFields: search.searchFields.length
        ? search.searchFields
        : filteredSearchType,
      sort: "-createdAt",
    })
  );

  return (
    <Users.Content
      title={
        <Flex justify={"space-between"}>
          {"사용자 관리"}
          <Button
            color="blue"
            onClick={() =>
              openModal(<RegisterForm />, "", "사용자 정보 등록", true)
            }
          >
            {"신규 등록"}
          </Button>
        </Flex>
      }
    >
      <Users.Box>
        <Flex gap={5} justify={"space-between"} mb={20}>
          <Select
            placeholder="페이지당 개수"
            data={pageOptions}
            onChange={(e) => {
              setPage(1);
              setPerPageRows(Number(e));
            }}
          ></Select>
          <SearchBox
            searchType={usersSearchType}
            setSearch={(searchedValue: any) => {
              setSearch(searchedValue);
              setPage(1);
            }}
          />
        </Flex>
        <UsersTable
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          sortColumns={sortColumns}
          setSortColumns={setSortColumns}
          users={data?.data?.rows as AuthSignupPost201Response[]}
        />
        <Flex justify={"center"} mt={20}>
          <Pagination
            value={page}
            total={data?.data?.totalPages as number}
            onChange={(value) => setPage(value)}
          />
        </Flex>
      </Users.Box>
    </Users.Content>
  );
}

export default UsersPage;
